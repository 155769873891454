<template>
    <transition name="fade">
        <div v-if="show" class="modal-wrapper">
            <div style="display: block" class="modal fade show" :id="id" tabindex="-1" aria-hidden="true" @click="hideByBackdrop">
                <div
                    @click="$event.stopPropagation()"
                    class="modal-dialog"
                    :class="{
                            'modal-dialog-scrollable': scrollable,
                            'modal-dialog-centered': centered,
                            [`modal-${size}`]: true
                        }"
                >
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hide">
                                <span aria-hidden="true">×</span>
                            </button>
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Modal",
    props: {
        id: {
            type: String,
            default: 'modal'
        },
        show: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
            validator: (value) => ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        staticBackdrop: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        show(value){
            if(value){
                document.body.classList.add('modal-open');
                this.$emit('showModal');
            } else{
                document.body.classList.remove('modal-open');
            }
        }
    },
    methods: {
        hide(){
            this.$emit('hideModal');
        },
        hideByBackdrop(){
            if(!this.staticBackdrop){
                this.hide()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.close {
    position: absolute;
    right: 17px;
    font-weight: normal;
    font-size: 3rem;
    top: 5px;

    &:focus {
        outline: none;
    }
}
.modal {
    &-wrapper {
        z-index: 9999;
        position: fixed;
        left: 0;
        top: 0;
        transform: none !important;
    }
}

.fade-leave-active,
.fade-enter-active {

    &, .modal {
        transition: all 0.75s cubic-bezier(0.5, 0, 0, 1.25);
    }

}

.fade-enter, .fade-leave-to {
    opacity: 0;

    .modal {
        opacity: 0;
        top: -100px;
    }
}

.fade-enter-to, .fade-leave {
    opacity: 1;

    .modal {
        opacity: 1;
        top: 0px;
    }
}

</style>